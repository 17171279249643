import { formatDistanceToNowStrict } from 'date-fns'
import { t } from 'i18next'
import React, { FC, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { areEqual, FixedSizeList as List } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import { isFeedbackDue } from '~/components/Calendar/CalendarView'
import SocialLinks from '~/components/Candidates/SocialLinks'
import SkeletonContainer from '~/components/Skeleton'
import { useClassBasedTopSpace } from '~/components/Subscription/TopSpace'
import { ISwitchLayoutView } from '~/components/SwitchLayout/SwitchLayoutView'
import configuration from '~/configuration'
import { IRouterWithID } from '~/core/@types/global'
import { DEFAULT_PAGE_SIZE } from '~/core/constants/enum'
import { Avatar } from '~/core/ui/Avatar'
import { Badge } from '~/core/ui/Badge'
import { Button } from '~/core/ui/Button'
import { IconButton } from '~/core/ui/IconButton'
import IconWrapper from '~/core/ui/IconWrapper'
import If from '~/core/ui/If'
import {
  KanbanDraggable,
  KanbanDraggableContent,
  KanbanDroppable
} from '~/core/ui/Kanban'
import { Skeleton } from '~/core/ui/Skeleton'
import { TypographyText } from '~/core/ui/Text'
import { Tooltip } from '~/core/ui/Tooltip'
import { cn } from '~/core/ui/utils'
import {
  defaultFormatDate,
  monthDayFormatDate
} from '~/core/utilities/format-date'
import { pushStateBrowser } from '~/core/utilities/is-browser'
import { shortEnLocale } from '~/lib/features/candidates/utilities'
import {
  NOTE_TAB,
  OVERVIEW_TAB
} from '~/lib/features/candidates/utilities/enum'
import QueryTenantJobApplicants from '~/lib/features/jobs/graphql/query-job-applicants'
import { useInfinityGraphPage } from '~/lib/features/jobs/hooks/use-infinity-graph-page'
import { IJobApplicants } from '~/lib/features/jobs/types'
import {
  JOB_APPLICANT_STATUS,
  JOB_STAGE_GROUP,
  PLACE_VIEW_PIPELINE_ENUM
} from '~/lib/features/jobs/utilities/enum'
import usePermissionCandidate from '~/lib/features/permissions/hooks/use-permission-candidate'
import usePermissionJob from '~/lib/features/permissions/hooks/use-permission-job'
import { IPlaceViewPipeline } from '~/lib/features/settings/hiring-pipelines/types'
import useBoundStore from '~/lib/store'
import { IRefetchActions, IStageKanban } from '../CandidatesTabContent'
import DisqualifyCandidateAction from './DisqualifyCandidateAction'
import ScheduleInterviewAction from './ScheduleInterviewAction'
import { adminAndMemberCanAction } from '~/core/utilities/permission'

const JobItem = ({
  isLoading,
  provided,
  actions,
  item,
  refetch,
  callback,
  style,
  isDragging,
  openPlacementDetailModel,
  jobTitle,
  companyName
}: {
  provided: any
  isLoading: boolean
  callback?: () => void
  refetch: () => void
  actions?: {
    configSwitchLayout: {
      path: Array<string>
      redirectUrls: Array<string>
    }
    setConfigSwitchLayout: (param: {
      path: Array<string>
      redirectUrls: Array<string>
    }) => void
    switchView: ISwitchLayoutView
    setSwitchView: (param: ISwitchLayoutView) => void
  }
  item: IJobApplicants
  style?: object
  isDragging?: boolean
  openPlacementDetailModel?: any
  jobTitle?: string
  companyName?: string
}) => {
  const { t } = useTranslation()
  const { actionInterview } = usePermissionJob()
  const { actionApplicantNote, actionApplicant } = usePermissionCandidate({})
  const { currentRole } = useBoundStore()

  const isFeedbackDueInterview =
    item?.nearestInterview?.fromDatetime || item?.lastestInterview?.fromDatetime
      ? isFeedbackDue({
          startTime:
            item?.nearestInterview?.fromDatetime ||
            item?.lastestInterview?.fromDatetime,
          hasFeedback: item.nearestInterview
            ? item.nearestInterview?.ikitFeedbacksSummary?.length > 0
            : item.lastestInterview?.ikitFeedbacksSummary?.length > 0
        })
      : false

  function getStyle() {
    // If you don't want any spacing between your items
    // then you could just return this.
    // I do a little bit of magic to have some nice visual space
    // between the row items
    const combined = {
      ...style,
      ...provided.draggableProps.style
    }

    // Being lazy: this is defined in our css file
    const grid = 8

    // when dragging we want to use the draggable style for placement, otherwise use the virtual style
    const result = {
      ...combined,
      height: isDragging ? combined.height : combined.height - grid,
      left: isDragging ? combined.left : combined.left + 1,
      width: `calc(${combined.width} - 2px)`,
      minWidth: isDragging ? 262 : 'auto',
      maxWidth: isDragging ? 262 : 'auto',
      marginBottom: grid
    }

    return result
  }
  if (isLoading) {
    return (
      <div className="relative mb-0 flex h-[68px] items-center space-x-2 rounded bg-white px-3 py-2.5 shadow-dialog hover:bg-[#FBFBFD]">
        <Skeleton className="h-6 w-6 min-w-[24px] rounded-full" />
        <div className="w-full space-y-1">
          <Skeleton className="h-2 w-2/3" />
          <Skeleton className="h-2 w-full" />
        </div>
      </div>
    )
  }

  const permissionForAction = item.status !== JOB_APPLICANT_STATUS.rejected
  return (
    <KanbanDraggableContent
      className="relative mb-0 hover:bg-[#FBFBFD]"
      style={getStyle()}
      provided={provided}>
      <div
        onClick={() => {
          if (actions?.setSwitchView) {
            actions?.setSwitchView({
              id: item.profile?.id,
              applicantId: item.id,
              view: 'candidates'
            })
            pushStateBrowser({
              state: {
                id: item.profile?.id,
                applicantId: item.id
              },
              unused: '',
              url: configuration.path.candidates.detail(
                item.profile?.id,
                item.id
              )
            })
          } else {
            window.open(
              configuration.path.candidates.detail(item.profile?.id, item.id),
              '_blank'
            )
          }
        }}
        className="group/item flex flex-col">
        <div className="flex items-center">
          <div
            className={cn(
              'flex w-full flex-1 items-center',
              permissionForAction ? 'group-hover/item:pr-[86px]' : ''
            )}>
            <div>
              <Tooltip content={item.profile?.fullName}>
                <TypographyText className="line-clamp-1 break-all text-sm font-medium text-gray-900 hover:cursor-pointer hover:underline">
                  {item.profile?.fullName}
                </TypographyText>
              </Tooltip>
            </div>
            <div className="ml-2" onClick={(e) => e.stopPropagation()}>
              {Object.keys(item.profile.links || {}).length > 0 ? (
                <SocialLinks
                  source={item.profile.links || {}}
                  maxItems={
                    Object.values(item.profile.links || {}).flat().length > 2
                      ? 1
                      : 2
                  }
                  size="2xs"
                />
              ) : null}
            </div>
          </div>
          {permissionForAction && (
            <div
              className="group/action invisible absolute right-[6px] bg-white group-hover/item:visible"
              onClick={(e) => e.stopPropagation()}>
              <div className="flex rounded border-[1px] border-solid border-gray-100 p-0.5 shadow-sm">
                <If condition={actionInterview.create}>
                  <div className="mr-1">
                    <ScheduleInterviewAction
                      applicantId={item.id}
                      candidateProfile={item.profile}
                      reload={() => {
                        refetch()
                        callback && callback()
                      }}
                    />
                  </div>
                </If>
                <If condition={actionApplicantNote.owned_update}>
                  <div className="mr-1">
                    <Tooltip content={`${t('tooltip:note')}`}>
                      <IconButton
                        onClick={() => {
                          if (actions?.setSwitchView) {
                            actions?.setSwitchView({
                              id: item.profile?.id,
                              applicantId: item.id,
                              view: 'candidates'
                            })
                            pushStateBrowser({
                              state: {
                                id: item.profile?.id,
                                applicantId: item.id,
                                tabs: NOTE_TAB
                              },
                              unused: '',
                              url: `${configuration.path.candidates.detail(
                                item.profile?.id,
                                item.id
                              )}?profile-tabs=${OVERVIEW_TAB}&tabs=${NOTE_TAB}`
                            })
                          } else {
                            window.open(
                              `${configuration.path.candidates.detail(
                                item.profile?.id,
                                item.id
                              )}?profile-tabs=${OVERVIEW_TAB}&tabs=${NOTE_TAB}`,
                              '_blank'
                            )
                          }
                        }}
                        type="secondary"
                        size="xs"
                        iconMenus="FileSignature"
                      />
                    </Tooltip>
                  </div>
                </If>
                <If condition={actionApplicant.update}>
                  <DisqualifyCandidateAction
                    callback={() => {
                      refetch()
                      callback && callback()
                    }}
                    applicantId={item.id}
                    email={item.profile?.email}
                    fullName={item.profile?.fullName}
                    id={item.profile?.id.toString()}
                    defaultValue={(item.profile?.applicants || []).filter(
                      (fi) => Number(fi.id) === Number(item.id)
                    )}
                  />
                </If>
              </div>
            </div>
          )}
          {item.flagNew && (
            <div
              className={cn(
                'ml-4',
                permissionForAction ? 'group-hover/item:hidden' : ''
              )}>
              <Badge color="green" radius="circular" size="sm">
                {t('label:new')}
              </Badge>
            </div>
          )}
        </div>

        <If condition={item?.createdAt || item?.updatedAt}>
          <div className="mt-2 flex justify-between">
            <div className="flex items-center space-x-2.5">
              <If condition={item?.createdAt}>
                <Tooltip
                  content={`${t('tooltip:createdAtDate', {
                    date: defaultFormatDate(new Date(item.createdAt))
                  })}`}>
                  <div className="flex items-center justify-center space-x-1">
                    <IconWrapper
                      name="Clock"
                      size={14}
                      className="text-gray-400"
                    />
                    <TypographyText className="text-xs font-medium text-gray-500">
                      {formatDistanceToNowStrict(new Date(item.createdAt), {
                        locale: shortEnLocale
                      })}
                    </TypographyText>
                  </div>
                </Tooltip>
              </If>

              <If condition={item?.updatedAt}>
                <Tooltip
                  content={`${t('tooltip:lastActivityDate', {
                    date: defaultFormatDate(new Date(item?.updatedAt))
                  })}`}>
                  <div className="flex items-center justify-center space-x-1">
                    <IconWrapper
                      name="Activity"
                      size={14}
                      className="text-gray-400"
                    />
                    <TypographyText className="text-xs font-medium text-gray-500">
                      {formatDistanceToNowStrict(new Date(item?.updatedAt), {
                        locale: shortEnLocale
                      })}
                    </TypographyText>
                  </div>
                </Tooltip>
              </If>
              <If
                condition={
                  item.profile.profileCvs && item.profile.profileCvs.length > 0
                }>
                <Tooltip content={`${t('tooltip:viewCV')}`}>
                  <Button
                    size="xs"
                    type="secondary"
                    iconMenus="Paperclip"
                    className="!h-5 !w-5"
                  />
                </Tooltip>
              </If>
              <If
                condition={
                  item.jobStage?.stageGroup !== JOB_STAGE_GROUP.hires && //Not Show interview badge from all stage type is Hired
                  (item?.nearestInterview?.fromDatetime ||
                    item?.lastestInterview?.fromDatetime)
                }>
                <Badge
                  color={isFeedbackDueInterview ? 'red' : 'blue'}
                  icon="CalendarCheck2"
                  size="md"
                  radius="rounded"
                  type="iconLeading">
                  {item?.nearestInterview?.fromDatetime ||
                  item?.lastestInterview?.fromDatetime
                    ? monthDayFormatDate(
                        new Date(
                          item?.nearestInterview?.fromDatetime ||
                            item?.lastestInterview?.fromDatetime
                        )
                      )
                    : ''}
                </Badge>
              </If>
              <If condition={item.placement}>
                {item?.placement?.hiredDate && (
                  <If
                    condition={adminAndMemberCanAction(currentRole?.code)}
                    fallback={
                      <Tooltip content={t('tooltip:hiredDate')}>
                        <div>
                          <Badge
                            icon="CheckCircle"
                            color="green"
                            size="md"
                            variant="outline"
                            type="iconLeading">
                            {monthDayFormatDate(
                              new Date(item.placement.hiredDate)
                            )}
                          </Badge>
                        </div>
                      </Tooltip>
                    }>
                    <Tooltip content={t('tooltip:viewPlacementInfo')}>
                      <div
                        className="cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation()
                          e.preventDefault()
                          openPlacementDetailModel({
                            placement: item.placement,
                            header: {
                              candidateName: item.profile?.fullName,
                              jobTitle: jobTitle,
                              companyName: companyName
                            },
                            onPlacementEdited: () => {
                              refetch()
                              return Promise.resolve()
                            }
                          })
                        }}>
                        <Badge
                          icon="CheckCircle"
                          color="green"
                          size="md"
                          variant="outline"
                          type="iconLeading">
                          {monthDayFormatDate(
                            new Date(item.placement.hiredDate)
                          )}
                        </Badge>
                      </div>
                    </Tooltip>
                  </If>
                )}
              </If>
            </div>
            <If condition={item.createdBy?.fullName}>
              <Tooltip
                content={`${t('tooltip:sourcedByUser', {
                  user: item.createdBy?.fullName || item.createdBy?.email
                })}`}>
                <Avatar
                  color={item?.createdBy?.defaultColour}
                  size="xs"
                  alt={item.createdBy?.fullName || item.createdBy?.email}
                  src={item.createdBy?.avatarVariants?.thumb?.url}
                />
              </Tooltip>
            </If>
          </div>
        </If>
      </div>
    </KanbanDraggableContent>
  )
}

const Row = React.memo(function Row(props: {
  index: number
  data: {
    isDragDisabledKanban?: boolean
    items?: IJobApplicants[]
    lastTotalCount?: number
    callback?: () => void
    refetch: () => void
    actions?: {
      configSwitchLayout: {
        path: Array<string>
        redirectUrls: Array<string>
      }
      setConfigSwitchLayout: (param: {
        path: Array<string>
        redirectUrls: Array<string>
      }) => void
      switchView: ISwitchLayoutView
      setSwitchView: (param: ISwitchLayoutView) => void
    }
    openPlacementDetailModel?: any
    jobTitle?: string
    companyName?: string
  }
  style?: object
}) {
  const { data, index, style } = props
  const {
    isDragDisabledKanban,
    items,
    lastTotalCount,
    actions,
    refetch,
    callback,
    openPlacementDetailModel,
    jobTitle,
    companyName
  } = data
  const item = items?.[index]

  if (lastTotalCount === index && index > DEFAULT_PAGE_SIZE) {
    return (
      <div style={{ ...style, pointerEvents: 'none' }}>
        <TypographyText className="mt-4 text-center text-base text-gray-500">
          {t('label:endOfList')}
        </TypographyText>
      </div>
    )
  }

  // We are rendering an extra item for the placeholder
  if (!item) {
    return null
  }

  return (
    <KanbanDraggable
      isDragDisabled={isDragDisabledKanban}
      key={item.id}
      draggableId={String(item.id)}
      index={index}>
      {(providedDraggable) => (
        <JobItem
          style={style}
          isLoading={false}
          provided={providedDraggable}
          actions={actions}
          item={item}
          refetch={refetch}
          callback={callback}
          openPlacementDetailModel={openPlacementDetailModel}
          jobTitle={jobTitle}
          companyName={companyName}
        />
      )}
    </KanbanDraggable>
  )
}, areEqual)

interface JobStageKanbanProps {
  getUUidV4?: string
  actions?: {
    configSwitchLayout: {
      path: Array<string>
      redirectUrls: Array<string>
    }
    setConfigSwitchLayout: (param: {
      path: Array<string>
      redirectUrls: Array<string>
    }) => void
    switchView: ISwitchLayoutView
    setSwitchView: (param: ISwitchLayoutView) => void
  }
  isDragDisabledKanban?: boolean
  placeViewPipeline?: IPlaceViewPipeline
  el: IStageKanban
  ind?: number
  callback?: () => void
  searchState?: string
  statusState?: string
  jobId?: IRouterWithID
  callbackLoaded?: (
    newValue: IStageKanban & {
      totalCount: number
    }
  ) => void
  refetchActions?: IRefetchActions
  isFirstLoading: boolean
  openPlacementDetailModel?: any
  jobTitle?: string
  companyName?: string
}

const JobStageKanban: FC<JobStageKanbanProps> = ({
  getUUidV4 = '',
  actions,
  isDragDisabledKanban,
  placeViewPipeline,
  el,
  ind,
  callback,
  searchState,
  statusState,
  jobId,
  callbackLoaded,
  refetchActions,
  isFirstLoading = true,
  openPlacementDetailModel,
  jobTitle,
  companyName
}) => {
  const { t } = useTranslation()
  const {
    setRefetchMyList,
    refetchMyList,
    ignoreListRefetch,
    refetchMyDelete,
    setRefetchMyDelete
  } = useBoundStore()

  const { data, refetch, fetchNextPage, hasNextPage } = useInfinityGraphPage({
    queryDocumentNote: QueryTenantJobApplicants,
    getVariable: useCallback(
      (page) => ({
        jobId: Number(jobId),
        jobStageId: Number(el.jobStageId),
        limit: DEFAULT_PAGE_SIZE,
        search: searchState,
        status: statusState,
        page
      }),
      [jobId, el.jobStageId, searchState, statusState]
    ),
    getPageAttribute: (_lastGroup, groups) => ({
      totalCount: _lastGroup?.jobApplicantsList?.metadata?.totalCount,
      pageLength: groups[0]?.jobApplicantsList?.collection?.length
    }),
    queryKey: [
      'kanban-jobApplicantsList',
      `${String(el.jobStageId)}${getUUidV4 ? `-${getUUidV4}` : ''}${
        statusState ? `-${statusState}` : ''
      }${searchState ? `-${searchState}` : ''}`
    ]
  })

  const totalCount =
    data?.pages?.[data?.pages?.length - 1]?.jobApplicantsList?.metadata
      ?.totalCount

  useEffect(() => {
    if (refetchMyList || refetchMyDelete) {
      if (!ignoreListRefetch?.includes('job-kanban')) {
        refetch()
      }
      setRefetchMyList(false)
      setRefetchMyDelete(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchMyList, refetchMyDelete])

  useEffect(() => {
    if (data && callbackLoaded) {
      callbackLoaded({
        ...el,
        totalCount,
        data: data?.pages
          ?.map(
            (item: {
              [type: string]: {
                collection: IJobApplicants[]
              }
            }) => item?.jobApplicantsList?.collection || []
          )
          .flat()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (refetchActions?.length) {
      for (let i = 0; i < refetchActions.length; i++) {
        if (refetchActions?.[i]?.label === el?.label) {
          if (refetchActions?.[i]?.action === 'refetch') {
            refetch()
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchActions])

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = (index: number) =>
    !hasNextPage || index < el?.data?.length

  // ---------- RENDERED ----------

  const topSpace = useClassBasedTopSpace({
    34: window.innerHeight - 216 - 18,
    default: window.innerHeight - 182 - 18
  })
  const height =
    placeViewPipeline === PLACE_VIEW_PIPELINE_ENUM.jobDetail ? topSpace : 400

  return (
    <>
      <div
        className={cn(
          'rounded-t-2 sticky top-0 z-10 flex min-h-[28px] items-center space-x-2 px-2 pt-[11px]',
          placeViewPipeline === PLACE_VIEW_PIPELINE_ENUM.jobDetail
            ? 'bg-gray-50'
            : 'bg-gray-100'
        )}>
        <Tooltip content={el.label}>
          <TypographyText className="line-clamp-1 text-xs font-semibold uppercase text-gray-500">
            {el.label}
          </TypographyText>
        </Tooltip>

        <Badge color="white" size="sm" radius="circular">
          {el?.totalCount || 0}
        </Badge>
      </div>

      <div className="min-w-[280px] px-2 pb-2 pt-[11px]">
        <SkeletonContainer
          showMoreLabel={`${t('common:infinity:showMore')}`}
          useLoading={false}
          isFirstLoading={isFirstLoading}
          renderCustomSkeleton={
            <div style={{ height }}>
              <div className="relative mb-0 flex h-[68px] items-center space-x-2 rounded bg-white px-3 py-2.5 shadow-dialog hover:bg-[#FBFBFD]">
                <Skeleton className="h-6 w-6 min-w-[24px] rounded-full" />
                <div className="w-full space-y-1">
                  <Skeleton className="h-2 w-2/3" />
                  <Skeleton className="h-2 w-full" />
                </div>
              </div>
            </div>
          }>
          <KanbanDroppable
            mode="virtual"
            droppableId={`${ind}`}
            renderClone={(provided, snapshot, data) => (
              <JobItem
                isLoading={!isItemLoaded(data.source.index)}
                provided={provided}
                actions={actions}
                item={el?.data?.[data.source.index]}
                refetch={refetch}
                callback={callback}
                isDragging={snapshot.isDragging}
                openPlacementDetailModel={openPlacementDetailModel}
                jobTitle={jobTitle}
                companyName={companyName}
              />
            )}>
            {(provided) => (
              <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={
                  totalCount > DEFAULT_PAGE_SIZE ? totalCount + 1 : totalCount
                }
                threshold={3}
                // @ts-expect-error
                loadMoreItems={fetchNextPage}>
                {({ onItemsRendered, ref }) => (
                  <List
                    onItemsRendered={onItemsRendered}
                    ref={ref}
                    outerRef={provided.innerRef}
                    height={height}
                    itemCount={
                      totalCount > DEFAULT_PAGE_SIZE
                        ? el?.data?.length + 1
                        : el?.data?.length
                    }
                    itemData={{
                      isDragDisabledKanban,
                      callback,
                      refetch,
                      actions,
                      items: el?.data,
                      lastTotalCount: el?.totalCount || 0,
                      openPlacementDetailModel,
                      jobTitle,
                      companyName
                    }}
                    itemSize={76}
                    width={264}>
                    {Row}
                  </List>
                )}
              </InfiniteLoader>
            )}
          </KanbanDroppable>
        </SkeletonContainer>
      </div>
    </>
  )
}

export default React.memo(JobStageKanban)
